import React from 'react'
// bootstrap ----------------------
import {  Modal } from 'react-bootstrap';

function ModalQuestion(props) {

  const QuestionSelected = props.question;
  
    return (
      <>
        <Modal
             {...props}
             centered
             aria-labelledby="Pregunta Frecuene"
        >
          <Modal.Body>{QuestionSelected}</Modal.Body>
        </Modal>
      </>
    );
  }
  
export default ModalQuestion;